<template>
    <div class="">
        <div v-if="(resourcesList.length >= 1 && !loading) || showEmpty" class="">
            <h3 class="card-title d-flex">
                Explore Transactions
            </h3>
            <div class="mt-4">
                <ItemList :allowAddButton="false" :title="title" :headers="headers" :inputData="resourcesList" caller="transactions" @on-item-click="handleSelected" @load-more="onLoadMore"/>
            </div>
        </div>
        <div v-if="resourcesList.length === 0 && !showEmpty" id="fallback" class="vs-con-loading__container">
            <div v-if="!loading">
                <vs-card>
                    <div class="login-tabs-container">
                        <vs-row>
                            <vs-col class="full-page-bg-color p-0 login-tabs-container" vs-type="flex" vs-xs="0" vs-sm="6" vs-lg="6">
                                <img src="@/assets/images/pages/login.png" alt="Payment Link" class="m-auto w-100 h-100">
                            </vs-col>
                            <vs-col class="login-tabs-container" vs-xs="12" vs-sm="6" vs-lg="6">
                                <div class="p-4 h-100 pb-4">
                                    <div class="mt-4">
                                        <span class="stripe"></span>
                                        <h6 class="d-inline text-muted">Utupay</h6>
                                    </div>
                                    <h2 class="mt-4 text-primary">Explore Transactions</h2>
                                    <p class="mt-2 lead font-weight-bold text-muted">
                                        Create and share a Utupay Payment Link to customers via email, SMS, whatsapp, messenger, and on any social media platform. Customers can complete payments to your account with your link through the Utupay mobile application or through a web browser.
                                    </p>
                                    <vs-button class="get-started"  @click="showEmpty = true" color="primary">Get started</vs-button>
                                </div>
                            </vs-col>
                        </vs-row>
                    </div>
                </vs-card>
            </div>
        </div>
    </div>
</template>

<script>
import ItemList from '../../custom/ItemList';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Transactions',
    components: { ItemList },
    mounted() {
        this.showLoading();
        let payload = {
            close: this.stopLoading
        };
        this.bindTransactions(payload);
    },
    computed: {
        //...mapState('resources', ['resourcesList']),
        ...mapGetters('transaction', {
            resource: 'getTransactions',
        })
    },
    data() {
        return {
            loading: true,
            showEmpty: false,
            title: '',
            headers: [
                {
                    key: 'customer_email',
                    value: 'Customer Email'
                }, {
                    key: 'transaction_id',
                    value: 'transaction Id'
                }, {
                    key: 'order_id',
                    value: 'Order Id'
                }, {
                    key: 'amount',
                    value: 'Amount'
                }, {
                    key: 'creation_date',
                    value: 'Creation Date'
                }, {
                    key: 'status',
                    value: 'Status'
                }
            ],
            resourcesList: []
        }
    },
    methods: {
        handleSuccess() {
            this.activePrompt=false;
        },
        handleSelected(sectorHead) {
            this.$router.push({
                name: 'SectorHeadDetails',
                params: {data: sectorHead}
            });
        },
        showLoading() {
            this.loading = true;
            this.$vs.loading({
                text: 'Loading transactions...',
                //type: 'point',
                container: '#fallback'
            });
        },
        stopLoading() {
            this.loading = false;
            this.$vs.loading.close('#fallback > .con-vs-loading');
        },
        onLoadMore(limit) {
            let payload = {
                collection: 'users', 
                account: 'sector_head',
                limit: limit,
                lastSnapshot: this.resourcesList[this.resourcesList.length -1]
            };
            this.bindMoreTransactions(payload);
        },
        ...mapActions('transaction', ['bindTransactions', 'bindMoreTransactions'])
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        resource(newValue, oldValue) {
            this.resourcesList = [...new Set([...this.resourcesList, ...newValue])];
            window.console.log("NEWV:", newValue, "OLDV:", oldValue, "RESOURCE:", this.resourcesList);
        }
    }
}
</script>

<style lang="scss" scoped>
    .login-tabs-container {
        min-height: 505px;

        .con-tab {
            padding-bottom: 14px;
        }

        .con-slot-tabs {
            margin-top: 1rem;
        }
    }

    .stripe {
        height: 2px;
        width: 45px;
        background-color: #32efa2;
        display: inline-block;
        margin-right: 5px;
    }

    .get-started {
        bottom: 140px;
        right: 50px;
        position: absolute;
    }
</style>